<template>
  <div class="flex-col page">
    <div class="flex-col header" style="padding-left: 0.5rem;">
      <div class="flex-row group_2" style="padding-bottom: 0rem;" @click="toBack">
        <img src="../../../assets/img/back.png" class="image_3" />
        <span class="text_1">  返回招聘首页</span>
        <!-- <span class="text_1" style="">职位</span> -->
      </div>

    </div>
    <div class="flex-col group_2">
      <div class="justify-between section_1">
        <span class="text_2">职位数量</span>
        <span class="text_3">{{ job_list.length }}个</span>
      </div>
      <div class="flex-col list">
        <div class="flex-col list-item" v-for="item in job_list" :id="item.id">
          <div class="flex-col">
            <div class="justify-between">
              <span class="text_4">{{ item.job_title }}</span>
              <span class="text_6" v-if="item.type =='2'">{{ item.num_applicants }}</span>
            </div>
            <div class="justify-between group_5" >
              <div class="flex-col text-wrapper">
                <span class="text_8">{{item.status_title}}</span>
              </div>
              <span class="text_10">{{item.type =='2' ? '招聘信息' : '求职信息'}}</span>
              <span class="text_10" v-if="item.type =='2'">咨询人数</span>
              <span class="text_10" v-else></span>
            </div>
          </div>
          <div class="flex-row group_6" >
            <div class="flex-row" >
              <img src="../../../assets/img/job_1.png" class="image_3" />
              <span class="text_12">{{ jobs_range_arr[item.jobs_range] }}</span>
            </div>
            <div class="flex-row group_8">
              <img src="../../../assets/img/job_2.png" class="image_5" />
              <span class="text_14">浏览{{ item.num_hits }}</span>
            </div>
          </div>
          <img src="../../../assets/img/job_3.png" class="image_7" />
          <div class="justify-between group_9">
            <div class="flex-col items-center text-wrapper_1" @click="toDetail(item.jobid)">
              <span class="text_16">查看</span>
            </div>
            <div class="flex-col items-center text-wrapper_2" @click="toEdit(item.jobid)">
              <span class="text_18">修改</span>
            </div>
            <div class="flex-col items-center text-wrapper_3" @click="to_select_user()">
              <span class="text_20">确认招工</span>
            </div>
            <div class="flex-col items-center text-wrapper_4" @click="toEditStatus(item.jobid,item.status)">
              <span class="text_22">{{ item.status =='0' ? '上架' : '下架' }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--    <div class="flex-col tab-bar">
      <div class="section_4"></div>
      <div class="justify-between group_13">
        <img
          src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953523541194.png"
          class="image_12"
        />
        <img
          src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953527690903.png"
          class="image_12"
        />
        <img
          src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953530986615.png"
          class="image_12"
        />
        <img
          src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953538549669.png"
          class="image_12"
        />
        <img
          src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/617a2ca7e4f1450011362b37/62836d1d38bbba00113dc3aa/16527817953549478556.png"
          class="image_12 image_17"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    components: {},
    data() {
      return {
        job_list: [], //job列表信息
        jobs_range_arr:['','全职','兼职'],
      };
    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return	;
      }
      this.getMyJobsList(); //获取我发布的招聘信息
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      toDetail(jobid) {
        this.$router.push({
          path: "/job_detail",
          query: {
            id: jobid,
          },
        });
      },
      toEdit(jobid) {
        this.$router.push({
          path: "/job_edit",
          query: {
            id: jobid,
          },
        });
      },
      toBack(where) {
        //进入招聘网站
        this.$router.push({
          path: "/job_list",
        });
        //顶部后退和返回按钮
        //  this.$router.back();
      },
      to_select_user(){
        //去选择本次都招聘了谁
        this.$router.push({
          path: "/marry",
        });
      },
      async toEditStatus(jobid, status) {
        //切换招聘状态,上架,下架
        let that = this;
        if (status == "1") {
          status = "0";
        } else {
          status = "1";
        }
        that.house_list = [];
        let uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "updateJobStatus",
          user: Cookies.get("kh"),
          id: jobid,
          status: status, //1 上架 0下架 2结束
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.getMyJobsList(); //获取我发布的招聘信息
          // let data = result.data;
          // if (data.length > 0) {
          //   for (var i = 0; i < data.length; i++) {
          //     //分解为数组,
          //     let list1 = data[i][5].toString().split(",");
          //     var sData = {
          //       jobid: data[i][0],
          //     };
          //     that.job_list.push(sData);
          //   }
          // }
        } else {
          // this.$router.push("login");
        }
      },
      async getMyJobsList() {
        //获取我发布的招聘信息
        let that = this;
        that.job_list = [];
        let uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: "getMyJobsList",
          add_user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              //分解为数组,
              let list1 = data[i][5].toString().split(",");
              let statusTitle = "";
              if (data[i][19] == "1") {
                statusTitle = "展示中";
              } else {
                statusTitle = "未上架不展示";
              }
              var sData = {
                jobid: data[i][0],
                type: data[i][1],
                job_title: data[i][2],
                monthly_pay1: data[i][3],
                monthly_pay2: data[i][4],
                welfare_treatment: data[i][5], //职务标签
                welfare_treatment1: list1[0],
                welfare_treatment2: list1[1],
                welfare_treatment3: list1[2],
                welfare_treatment4: list1[3],
                jobs_range: data[i][6],
                head_count: data[i][7],
                job_desc: data[i][8],
                education: data[i][9],
                work_years: data[i][10],
                work_city: data[i][11],
                work_place: data[i][12],
                tel: data[i][13],
                edit_time: data[i][14],
                num_applicants: data[i][15],
                num_hits: data[i][16],
                job_unit: data[i][17],
                hr_name: data[i][18],
                status: data[i][19],
                status_title: statusTitle
                // kh: data[i].kh,
              };
              that.job_list.push(sData);
            }
          }
        } else {
          //this.$router.push("login");
        }
      },
      add_job() {
        //发布新的招聘信息
        this.$router.push({
          path: "/job_edit",
          query: {
            type: "add",
          },
        });
      },
    },
  };
</script>

<style scoped lang="css">
  .list-item {
    padding: 1rem 1rem 0;
    background-color: #ffffff;
    border-radius: 0.5rem;
  }

  .image_12 {
    width: 1.69rem;
    height: 1.69rem;
  }

  .group_6 {
    margin-top: 0.81rem;
  }

  .image_7 {
    margin-top: 0.81rem;
    width: 19.44rem;
    height: 0.063rem;
  }

  .group_9 {
    padding: 1.28rem 0 1.25rem;
  }

  .group_5 {
    margin-top: 0.69rem;
  }

  .group_8 {
    margin-left: 2.5rem;
  }

  .text-wrapper_1 {
    padding: 0.19rem 0;
    border-radius: 0.13rem;
    width: 3.75rem;
    height: 1.75rem;
    border: solid 0.063rem #36c1bc;
  }

  .text-wrapper_2 {
    padding: 0.19rem 0;
    border-radius: 0.13rem;
    width: 3.75rem;
    height: 1.75rem;
    border: solid 0.063rem #36c1bc;
  }

  .text-wrapper_3 {
    padding: 0.19rem 0;
    border-radius: 0.13rem;
    width: 3.75rem;
    height: 1.75rem;
    border: solid 0.063rem #f6ad4f;
  }

  .text-wrapper_4 {
    padding: 0.25rem 0;
    background-color: #f6ad4f;
    border-radius: 0.13rem;
    width: 3.75rem;
    height: 1.75rem;
  }

  .text_4 {
    margin-top: 0.13rem;
    color: #292929;
    font-size: 1rem;
    font-family: "PingFangSC-Semibold";
    font-weight: 600;
    line-height: 1.38rem;
  }

  .text_6 {
    margin-right: 1rem;
    color: #292929;
    font-size: 1.13rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    line-height: 1.56rem;
  }

  .text-wrapper {
    margin-bottom: 0.19rem;
    padding: 0.25rem 0 0.19rem;
    background-color: #f6ad4f33;
    border-radius: 0.13rem;
    height: 1.5rem;
  }

  .text_10 {
    margin-top: 0.63rem;
    color: #999999;
    font-size: 0.75rem;
    font-family: "PingFangSC-Regular";
    line-height: 1.06rem;
  }

  .image_3 {
    margin: 0.13rem 0 0.19rem;
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
  }

  .text_12 {
    margin-left: 0.38rem;
    color: #616161;
    font-size: 0.75rem;
    font-family: "PingFangSC-Regular";
    line-height: 1.06rem;
  }

  .image_5 {
    margin: 0.13rem 0 0.19rem;
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
  }

  .text_14 {
    margin-left: 0.38rem;
    color: #616161;
    font-size: 0.75rem;
    font-family: "PingFangSC-Regular";
    line-height: 1.06rem;
  }

  .text_16 {
    color: #36c1bc;
    font-size: 0.88rem;
    font-family: "PingFangSC-Regular";
    line-height: 1.25rem;
  }

  .text_18 {
    color: #36c1bc;
    font-size: 0.88rem;
    font-family: "PingFangSC-Regular";
    line-height: 1.25rem;
  }

  .text_20 {
    color: #f6ad4f;
    font-size: 0.88rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    line-height: 1.25rem;
  }

  .text_22 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    line-height: 1.25rem;
  }

  .text_8 {
    margin: 0 0.5rem;
    color: #f6ad4f;
    font-size: 0.75rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    line-height: 1.06rem;
  }

  .page {
    background-color: #f8f8f8ff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .header {
    padding: 0.63rem 0.88rem 0.75rem 2.16rem;
    background-color: #36c1bc;
    height: 2.6rem;
  }

  .group_2 {
    padding-bottom: 12.13rem;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .tab-bar {
    padding: 0.063rem 2.03rem 0;
    background-color: #ffffff;
    border-radius: 1.25rem 1.25rem 0px 0px;
  }

  .text_1 {
    /* margin-top: 1.38rem; */
    align-self: center;
    color: #ffffff;
    font-size: 1rem;
    font-family: "PingFangSC-Semibold";
    font-weight: 600;
    line-height: 1.38rem;
  }

  .section_1 {
    padding: 0.5rem 0.81rem 0.5rem 1rem;
    background-color: #ffffff;
    box-shadow: 0px 0.063rem 0.19rem 0.13rem #0000000d;
  }

  .list {
    margin-top: 1rem;
    padding: 0 1rem;
  }

  .section_4 {
    margin-right: 4.69rem;
    align-self: flex-end;
    background-color: #444444;
    border-radius: 0px 0px 0.25rem 0.25rem;
    width: 1.25rem;
    height: 0.25rem;
  }

  .group_13 {
    padding: 1.34rem 0 1.66rem;
  }

  .text {
    color: #232323;
    font-size: 0.88rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  .group_1 {
    margin: 0.22rem 0 0.25rem;
  }

  .text_2 {
    color: #292929;
    font-size: 0.88rem;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    line-height: 1.25rem;
  }

  .text_3 {
    color: #f6ad4f;
    font-size: 1rem;
    font-family: "PingFangSC-Semibold";
    font-weight: 600;
    line-height: 1.38rem;
  }

  .list-item:last-of-type {
    margin-top: 1rem;
  }

  .image_17 {
    margin-right: 0.063rem;
  }

  .image {
    margin-top: 0.094rem;
    width: 1.13rem;
    height: 0.69rem;
  }

  .image_1 {
    margin-left: 0.25rem;
    width: 1rem;
    height: 0.75rem;
  }

  .image_2 {
    margin-left: 0.25rem;
    width: 1.56rem;
    height: 0.72rem;
  }

  .view_11 {
    padding: 0 0.16rem;
  }

  .view_14 {
    border: solid 0.063rem #616161;
  }

  .view_15 {
    border: solid 0.063rem #616161;
  }

  .view_16 {
    border: solid 0.063rem #616161;
  }

  .view_17 {
    background-color: #999999;
  }

  .view_10 {
    background-color: #f0f0f0;
    width: 3.25rem;
  }

  .image_9 {
    width: 0.44rem;
  }

  .text_28 {
    margin-left: 0.53rem;
  }

  .image_10 {
    margin: 0.25rem 0 0.25rem;
    height: 0.56rem;
  }

  .text_30 {
    color: #616161;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
  }

  .text_31 {
    color: #616161;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
  }

  .text_32 {
    color: #616161;
  }

  .text_26 {
    margin: initial;
    color: #999999;
  }

  /************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      "Microsoft Yahei", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  #app {
    width: 100vw;
    height: 100vh;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .justify-center {
    display: flex;
    justify-content: center;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .justify-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .justify-around {
    display: flex;
    justify-content: space-around;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .items-start {
    display: flex;
    align-items: flex-start;
  }

  .items-center {
    display: flex;
    align-items: center;
  }

  .items-end {
    display: flex;
    align-items: flex-end;
  }
</style>
